
















































































































import { Vue, Component, Emit, Prop, VModel } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { LoaderComponent } from 'vue-loading-overlay';
import { sortBy } from 'lodash';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { FormHelper } from '@/utils/helpers/form-helper';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import Field from '@/components/field.vue';
import ConversationModel from '@/models/crm/conversation.model';
import ConversationService from '@/services/crm/conversation.service';
import WysiwygEditor from '@/components/wysiwyg-editor.vue';
import { IKeyValue } from '@/interfaces/key-value.interface';
import OriginModel from '@/models/crm/origin.model';
import GroupModel from '@/models/crm/group.model';
import HistoryTypeModel from '@/models/crm/history-type.model';
import ActivityService from '@/services/crm/activity.service';
import ContactModel from '@/models/crm/contact.model';
import ConversationUserPermissionsModel from '@/models/crm/conversation-user-permissions.model';
import SettingsModel from '@/models/crm/settings.model';
import SettingsService from '@/services/crm/settings.service';

interface IChatContactFormModel {
  description: string | null;
  mistake: boolean;
  justified: boolean;
  origin: OriginModel | null;
  historyType: HistoryTypeModel | null;
  groupArea: GroupModel | null;
  consolidateSale: boolean;
  contact: ContactModel | null;
}

@Component({
  components: {
    Field,
    WysiwygEditor,
  },
})
export default class CrmChatCloseForm extends Vue {
  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @VModel()
  model!: IChatContactFormModel;

  @Prop({ required: true })
  conversations!: Array<ConversationModel>;

  @Prop({ default: true })
  isPopup!: boolean;

  @Prop()
  mistake!: boolean;

  @Prop()
  justified!: boolean;

  @Prop()
  justifiedText!: string;

  @Prop()
  transfer!: boolean;

  @Prop()
  newAttendant!: number;

  @Prop({ required: true })
  conversationUserPermission!: ConversationUserPermissionsModel;

  formIsValid = true;

  rules: IKeyValue<CallableFunction[]> = {
    description: [ValidationRules.required],
  };

  originOptions: OriginModel[] = [];

  historyTypeOptions: HistoryTypeModel[] = [];

  groupOptions: GroupModel[] = [];

  loadingHistoryTypeOptions = false;

  settings: SettingsModel | null = null;

  async mounted(): Promise<void> {
    this.settings = await this.settingsService.getSettings();
    if (!this.mistake && !this.justified) {
      if (this.settings.flagObrigatoriedadeFechamentoConversa) {
        this.rules.historyType = [ValidationRules.required];
        this.rules.origin = [ValidationRules.required];
        this.rules.groupArea = [ValidationRules.required];
      }
    }

    if (this.conversations[0].contato) {
      this.model.contact = this.conversations[0].contato;
    }

    const loader = this.setBusyLoader();
    try {
      const tasks: Promise<void>[] = [];

      if (!this.mistake && !this.justified) {
        tasks.push(this.loadOrigins());
        tasks.push(this.loadGroups());
      }

      await Promise.all(tasks);

      if (this.mistake) {
        this.model.mistake = true;
      } else if (this.justified) {
        this.model.justified = true;
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async onSave(): Promise<void> {
    if (this.model.mistake || this.model.justified) {
      this.rules = {
        description: [ValidationRules.required],
      };
    } else if (this.settings?.flagObrigatoriedadeFechamentoConversa) {
      this.rules = {
        description: [ValidationRules.required],
      };
      this.rules.historyType = [ValidationRules.required];
      this.rules.origin = [ValidationRules.required];
      this.rules.groupArea = [ValidationRules.required];
    }

    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.setBusyLoader();
      try {
        if (this.transfer) {
          if (!this.newAttendant) {
            this.$notify.error(this.$t('crm.chatCloseForm.messages.couldNotFoundAttendantToTransferConversation'));
            return;
          }

          await this.conversationService.transferConversation(
            this.conversations[0],
            this.newAttendant,
            this.model.mistake,
            this.model.justified,
            this.model.description as string,
            this.model.origin as OriginModel,
            this.model.historyType as HistoryTypeModel,
            this.model.consolidateSale,
            this.model.contact?.id as number,
          );

          this.model.mistake = false;
          this.model.justified = false;
          this.model.description = '';
          this.model.origin = null;
          this.model.historyType = null;
          this.model.consolidateSale = false;
          this.model.groupArea = null;

          this.$notify.success(this.$t('crm.chatCloseForm.messages.successfullyTransfered'));
        } else {
          await this.conversationService.closeConversation(
            this.conversations[0],
            this.model.mistake,
            this.model.justified,
            this.model.description as string,
            this.model.origin as OriginModel,
            this.model.historyType as HistoryTypeModel,
            this.model.consolidateSale,
            this.model.contact?.id as number,
          );

          this.model.mistake = false;
          this.model.justified = false;
          this.model.description = '';
          this.model.origin = null;
          this.model.historyType = null;
          this.model.consolidateSale = false;
          this.model.groupArea = null;

          this.$notify.success(this.$t('crm.chatCloseForm.messages.successfullyClose'));
        }

        this.$emit('complete', this.conversations[0]);
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  onGroupChange(model: GroupModel): void {
    this.loadHistoryTypes(model.id);
  }

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
    this.model.mistake = false;
    this.model.justified = false;
    this.model.description = '';
    this.model.origin = null;
    this.model.historyType = null;
    this.model.consolidateSale = false;
    this.model.groupArea = null;
  }

  get showConsolidatedSale(): boolean {
    return (!this.mistake && !!this.model.historyType?.flagPassivoVenda)
    || (!this.justified && !!this.model.historyType?.flagPassivoVenda);
  }

  private async loadOrigins(): Promise<void> {
    this.originOptions = sortBy(await this.activityService.getOrigins(10), 'descricao');
  }

  private async loadHistoryTypes(groupId: number): Promise<void> {
    this.loadingHistoryTypeOptions = true;
    this.historyTypeOptions = sortBy(await this.activityService.getHistoryTypes(groupId), 'nome');
    this.loadingHistoryTypeOptions = false;
  }

  private async loadGroups(): Promise<void> {
    this.groupOptions = sortBy(await this.activityService.getGroups(), 'descricao');
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.chatCloseFormContainer,
      canCancel: false,
    });
  }
}
