






























































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import { SelectionChangedEvent, GridReadyEvent, ValueGetterParams } from '@ag-grid-community/core';
import { isArray } from 'lodash';
import CrmAppBar from '@/components/crm/app-bar.vue';
import DateRangeFilter from '@/components/date-range-filter.vue';
import { IDateRangeConfig } from '@/interfaces/date-range-config.interface';
import Tooltip from '@/components/tooltip.vue';
import CrmChatCloseForm from '@/components/crm/chat-close-form.vue';
import Field from '@/components/field.vue';
import DataGridFilter from '@/components/data-grid-filter.vue';
import DatePickerField from '@/components/date-picker-field.vue';
import TimePickerField from '@/components/time-picker-field.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import RouterService from '@/services/router.service';
import ContentDialog from '@/components/content-dialog.vue';
import { DateHelper } from '@/utils/helpers/date-helper';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { IGridCellEvent } from '@/interfaces/grid-cell-clicked.interface';
import { GridHelper } from '@/utils/helpers/grid-helper';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import ConversationService from '@/services/crm/conversation.service';
import AttendanceModel from '@/models/crm/attendance.model';
import ConversationDepartmentModel from '@/models/crm/conversation-department.model';
import UserModel from '@/models/user.model';
import ActivityService from '@/services/crm/activity.service';
import ConversationModel from '@/models/crm/conversation.model';
import ContactService from '@/services/crm/contact.service';
import CrmChatHistoryMessagesViewer from '../../components/crm/chat-history-messages-viewer.vue';
import ContactTypeModel from '@/models/crm/contact-type.model';
import { ConversationTypeEnum } from '@/enums/crm/conversation-type.enum';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import OriginModel from '@/models/crm/origin.model';
import GroupModel from '@/models/crm/group.model';
import HistoryTypeModel from '@/models/crm/history-type.model';
import ContactModel from '@/models/crm/contact.model';
import ConversationUserPermissionsModel from '@/models/crm/conversation-user-permissions.model';

interface IChatContactFormModel {
  description: string | null;
  mistake: boolean;
  justified: boolean;
  origin: OriginModel | null;
  historyType: HistoryTypeModel | null;
  groupArea: GroupModel | null;
  consolidateSale: boolean;
  contact: ContactModel | null;
}

type DataGridFilterConfig = {
  client: string[] | null;
  prospect: number[] | null;
  periodType: PeriodTypeEnum;
  period: (Date | null)[];
  contactType: ContactTypeModel | null;
  contactNumber: string[] | null;
  department: ConversationDepartmentModel[] | null;
  attendant: AttendanceModel[] | null;
  lastMessageDate: Date | null;
  lastMessageTime: Date | null;
  protocolNumber: string | null;
  contact: string[] | null;
};

enum PeriodTypeEnum {
  OpenDate = 'OPEN_DATE',
  ClosingDate = 'CLOSING_DATE',
}

enum ContactTypesEnum {
  Client = ConversationTypeEnum.Client,
  Prospect = ConversationTypeEnum.Prospect,
}

interface ISelectOption<T> {
  code: T;
  description: string;
}

@Component({
  components: {
    CrmAppBar,
    CrmChatCloseForm,
    Field,
    DataGridFilter,
    DateRangeFilter,
    DatePickerField,
    TimePickerField,
    Tooltip,
    AgGridWrapper,
    AgGridVue,
    ContentDialog,
    CrmChatHistoryMessagesViewer,
  },
})
export default class CrmCloseChats extends Vue {
  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  btnDelete = false;

  btnSave = false;

  btnEnabled = 0;

  etapa1 = 1;

  etapa2 = 2;

  etapa3 = 3;

  grid: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'dataUltimaMensagem', sort: 'desc' }],
    columnDefs: [
      GridHelper.getSelectionColDef(),
      {
        headerName: `${this.$t('crm.view.chats.grid.openDate')}`,
        colId: 'openDate',
        field: 'dataInicio',
        maxWidth: 155,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const date = params.data.dataInicio;
          return DateHelper.formatToIsoDateTimeString(date);
        },
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value, 'dateTime'),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.lastAttendance')}`,
        colId: 'lastAttendance',
        field: 'dataUltimaMensagem',
        maxWidth: 155,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const date = params.data.dataUltimaMensagem || params.data.dataInicio || params.data.dataInclusao;
          return DateHelper.formatToIsoDateTimeString(date);
        },
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value, 'dateTime'),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.contactType')}`,
        colId: 'contactType',
        field: 'tipo',
        flex: 0.8,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            return params.data?.tipo.toLowerCase().includes(ClientTypeEnum.Client) ? 'Cliente' : 'Prospect';
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.legalName')}`,
        colId: 'legalName',
        field: 'cliente.nome',
        flex: 0.8,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            return params.data?.cliente?.nome;
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.contactName')}`,
        colId: 'contactName',
        field: 'contato.nome',
        flex: 0.8,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            return params.data?.contato?.nome;
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.protocol')}`,
        colId: 'protocol',
        field: 'protocolo',
        cellClass: 'cell-grid-link',
        maxWidth: 150,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.attendant')}`,
        colId: 'attendant',
        field: 'atendente.nome',
        flex: 0.5,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.department')}`,
        colId: 'department',
        field: 'departamento.nome',
        flex: 0.5,
        sortable: true,
      },
    ],
  };

  items: ConversationModel[] = [];

  selected: ConversationModel[] = [];

  selectedFromFilter: ConversationModel[] = [];

  res: ConversationModel[] = [];

  closeChatmodel: IChatContactFormModel = {
    description: null,
    historyType: null,
    origin: null,
    groupArea: null,
    contact: null,
    mistake: false,
    justified: false,
    consolidateSale: false,
  };

  // #region filters

  predefinedPeriodRanges: IDateRangeConfig[] = this.getDateRanges();

  multipleFilterChanged = false;

  filters: DataGridFilterConfig = {
    client: null,
    prospect: null,
    periodType: PeriodTypeEnum.OpenDate,
    period: [],
    contactType: null,
    contactNumber: null,
    department: null,
    attendant: null,
    lastMessageDate: null,
    lastMessageTime: null,
    protocolNumber: null,
    contact: null,
  };

  periodTypeOptions: ISelectOption<PeriodTypeEnum>[] = [];

  contactTypeOptions: ISelectOption<ContactTypesEnum>[] = [];

  contactNumberOptions: string[] = [];

  departmentOptions: ConversationDepartmentModel[] = [];

  contactOptions: string[] = [];

  attendantOptions: UserModel[] = [];

  conversationUserPermission!: ConversationUserPermissionsModel;

  // #endregion

  dialogConfig: IKeyValue<IDialogConfig> = {
    history: {
      show: false,
      conversation: null,
    },
  };

  async created(): Promise<void> {
    this.conversationUserPermission = await this.conversationService.getUserPermissions();
    const loader = this.$loading.show();
    try {
      await this.loadItems();
      await this.loadFilterOptions();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async onCellClick(event: IGridCellEvent<ConversationModel>): Promise<void> {
    if (this.selected.includes(event.data)) {
      this.selected.splice(Number(event.node.rowIndex), 1);
      event.node.setSelected(false);
    } else {
      this.selected.push(event.data);
      event.node.setSelected(true);
    }
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: ConversationModel[]): void {
    this.selected = selected;
  }

  onExport(selected: ConversationModel[]): void {
    if (this.grid) {
      const onlySelected = !!selected.length && this.items.length !== selected.length;
      const columnKeys = this.gridSettings.columnDefs
        .filter((x) => !x.checkboxSelection)
        .map((x) => x.colId || x.field || '');

      this.grid.api.exportDataAsExcel({
        onlySelected,
        columnKeys,
        allColumns: true,
        author: 'IBtech',
        sheetName: 'Conversas',
        fileName: ConversationService.generateConversationReportExportFilename(new Date()),
      });
    }
  }

  setRows(isPrevious: boolean):void {
    if (this.grid && this.grid.api) {
      if (isPrevious) {
        this.grid.api.setRowData(this.selectedFromFilter);
      } else {
        this.grid.api.setRowData(this.grid?.api.getSelectedRows());
      }
      this.grid.api.selectAll();
    }
  }

  onClickDelete():void {
    this.btnDelete = true;
    this.btnEnabled = this.etapa1;
    if (this.selectedFromFilter.length === 0) {
      this.selectedFromFilter = this.selected;
    }
    this.setRows(false);
  }

  onNextStep():void {
    if (this.btnEnabled !== this.etapa2 || (this.btnEnabled === this.etapa2
    && this.closeChatmodel.origin !== null && this.closeChatmodel.groupArea !== null
    && this.closeChatmodel.historyType !== null && this.closeChatmodel.description !== null
    && !this.closeChatmodel.mistake && !this.closeChatmodel.justified) || (this.btnEnabled === this.etapa2
    && (this.closeChatmodel.mistake || this.closeChatmodel.justified))) {
      this.btnEnabled += this.etapa1;
    } else {
      this.$notify.error('Informe valores para os campos origem, grupo/área, tipo de histórico e/ou descrição!');
    }
  }

  onPreviousStep():void {
    this.btnEnabled -= this.etapa1;
    if (this.btnEnabled !== this.etapa3) {
      this.setRows(true);
    }
  }

  async onSave():Promise<void> {
    this.btnSave = true;
    this.gridSettings.loading = true;
    const conversationIds = new Array<string>();
    this.selected.forEach((conversation) => {
      conversationIds.push(conversation.id.toString());
    });
    const loader = this.$loading.show();
    try {
      let execucoes = Math.ceil(Number(conversationIds.length / 100));
      execucoes = execucoes === 0 ? 1 : execucoes;
      let begin = 0;
      let end = 100;
      for (let execucao = 0; execucao < execucoes; execucao += 1) {
        const selectedChunked = conversationIds.slice(begin, end > conversationIds.length
          ? conversationIds.length : end);
        this.saveConversations(selectedChunked);
        begin += 100;
        end += 100;
      }
    } catch (err) {
      console.log(err);
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
      this.$notify.success('Conversas fechadas com sucesso!');
      this.selected = [];
      this.selectedFromFilter = [];
      this.filters = {
        client: null,
        prospect: null,
        periodType: PeriodTypeEnum.OpenDate,
        period: [],
        contactType: null,
        contactNumber: null,
        department: null,
        attendant: null,
        lastMessageDate: null,
        lastMessageTime: null,
        protocolNumber: null,
        contact: null,
      };
      this.closeChatmodel = {
        description: null,
        historyType: null,
        origin: null,
        groupArea: null,
        contact: null,
        mistake: false,
        justified: false,
        consolidateSale: false,
      };
      this.btnEnabled = 0;
      this.btnDelete = false;
      this.btnSave = false;
      this.loadItems();
    }
  }

  async saveConversations(ids: Array<string>): Promise<void> {
    await this.conversationService.closeConversations(ids,
      this.closeChatmodel.mistake ? this.closeChatmodel.mistake : false,
      this.closeChatmodel.justified ? this.closeChatmodel.justified : false,
      this.closeChatmodel.description ? this.closeChatmodel.description : '',
      this.closeChatmodel.origin ? this.closeChatmodel.origin : undefined,
      this.closeChatmodel.historyType ? this.closeChatmodel.historyType : undefined,
      this.closeChatmodel.consolidateSale ? this.closeChatmodel.consolidateSale : false)
      .then((conversations) => {
        conversations.forEach((conversation) => {
          this.res.push(conversation);
        });
      });
  }

  onCancel(): void {
    this.btnEnabled = this.etapa1;
  }

  get filteredItems(): ConversationModel[] {
    let itemsAux = this.items;

    if (this.filters.period && this.filters.period[0]) {
      const start = dayjs(this.filters.period[0]);
      itemsAux = itemsAux.filter(
        (item) => item.dataInicio !== null
          && (dayjs(item.dataInicio).isAfter(start) || dayjs(item.dataInicio).isSame(start)),
      );
    }

    if (this.filters.period && this.filters.period[1]) {
      const end = dayjs(this.filters.period[1]);
      itemsAux = itemsAux.filter(
        (item) => item.dataFechamento !== null
          && (dayjs(item.dataInicio).isBefore(end) || dayjs(item.dataInicio).isSame(end)),
      );
    }

    if (this.filters.contactType !== null) {
      itemsAux = this.items.filter((item) => this.filters.contactType?.descricao.toLowerCase().includes(item.tipo));
    }

    const contacts = this.filters.contact !== null ? this.filters.contact : [];

    if (this.filters.contact !== null) {
      itemsAux = this.items.filter((item) => item.contato && item.contato.nome
        && contacts.filter((contact) => contact.toLowerCase().includes(
          item.contato.nome.toLowerCase(),
        )).length > 0);
    }

    const contactNumbers = this.filters.contactNumber !== null ? this.filters.contactNumber : [];
    if (this.filters.contactNumber !== null) {
      itemsAux = this.items.filter((item) => item.contato && item.contato.whatsapp
        && contactNumbers.filter((number) => item.contato.whatsapp
          .includes(number)).length > 0);
    }

    const departments = this.filters.department !== null ? this.filters.department : [];
    if (this.filters.department !== null) {
      itemsAux = this.items.filter((item) => departments.filter((department) => item.departamento
        && item.departamento.nome && item.departamento.nome.toLowerCase()
        .includes(department.nome.toLowerCase())).length > 0);
    }

    const attendants = this.filters.attendant !== null ? this.filters.attendant : [];
    if (this.filters.attendant !== null) {
      itemsAux = this.items.filter((item) => attendants.filter((attendant) => item.atendente
        && item.atendente.nome && item.atendente.nome.toLowerCase()
        .includes(attendant.nomeAtendente.toLowerCase())).length > 0);
    }

    if (this.filters.protocolNumber !== null) {
      itemsAux = this.items.filter((item) => item.protocolo === this.filters.protocolNumber);
    }

    if (this.filters.lastMessageDate !== null && this.filters.lastMessageTime !== null) {
      itemsAux = this.items.filter((item) => item.dataUltimaMensagem
        && dayjs(item.dataUltimaMensagem.toString()).set('second', 0)
          .toString().includes(dayjs(this.filters.lastMessageDate?.toString())
            .set('hour', dayjs(this.filters.lastMessageTime?.toString(), 'HH:mm').hour())
            .set('minute', dayjs(this.filters.lastMessageTime?.toString(), 'HH:mm').minute()).toString()));
    }

    return itemsAux;
  }

  get activeFilters(): number {
    let active = 0;
    const filtersToIgnore = ['periodType'];
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      const filter = this.filters[key];
      switch (key) {
        default:
          if (!filtersToIgnore.includes(key) && filter && !(isArray(filter) && !filter.length)) {
            active += 1;
          }
      }
    });

    return active;
  }

  get filtered(): boolean {
    if (this.filters.period.length !== 0 || this.filters.contactType !== null || this.filters.contact !== null
      || this.filters.contactNumber !== null || this.filters.department !== null || this.filters.attendant !== null
      || this.filters.protocolNumber !== null || (this.filters.lastMessageDate !== null
      && this.filters.lastMessageTime !== null)) {
      return true;
    }
    return false;
  }

  get viewTitle(): string {
    return this.$t('crm.view.closeChats.title').toString();
  }

  get openDate(): string {
    return this.$t('crm.view.closeChats.filter.openDate').toString();
  }

  get lastMessageDate(): string {
    return this.$t('crm.view.closeChats.filter.lastMessageDate').toString();
  }

  get warningApplyFilters(): string {
    return this.$t('crm.view.closeChats.warningApplyFilters').toString();
  }

  get closeReview(): string {
    return this.$t('crm.view.closeChats.closeReview').toString();
  }

  get classifyClosingConversations(): string {
    return this.$t('crm.view.closeChats.classifyClosingConversations').toString();
  }

  get confirmBeforeClose(): string {
    return `${this.$t('crm.view.closeChats.confirmBeforeClose')}`;
  }

  get warningBeforeClose(): string {
    const conversations = this.selected.length.toString();
    return `${this.$t('crm.view.closeChats.warningBeforeClose', { conversations })}`;
  }

  get warningContinue(): string {
    return `${this.$t('crm.view.closeChats.warningContinue')}`;
  }

  get previous(): string {
    return `${this.$t('crm.view.closeChats.stepper.previous')}`;
  }

  get next(): string {
    return `${this.$t('crm.view.closeChats.stepper.next')}`;
  }

  get save(): string {
    return `${this.$t('crm.view.closeChats.stepper.save')}`;
  }

  private async loadItems(): Promise<void> {
    this.gridSettings.loading = true;
    this.items = await this.conversationService.getOpenConversations();
    this.gridSettings.loading = false;
  }

  private async loadFilterOptions(): Promise<void> {
    this.periodTypeOptions = this.getPeriodTypes();
    this.contactTypeOptions = this.getContactTypes();
    this.contactOptions = this.getContacts();
    this.contactNumberOptions = this.getContactNumbers();

    const tasks = [
      this.getDepartments().then((result) => {
        this.departmentOptions = result;
      }),
      this.getAttendants().then((result) => {
        this.attendantOptions = result;
      }),
    ];

    await Promise.all(tasks);
  }

  private getPeriodTypes(): ISelectOption<PeriodTypeEnum>[] {
    return [
      {
        code: PeriodTypeEnum.OpenDate,
        description: this.$t('crm.view.chats.filter.openDate').toString(),
      },
      {
        code: PeriodTypeEnum.ClosingDate,
        description: this.$t('crm.view.chats.filter.closingDate').toString(),
      },
    ];
  }

  private getContactTypes(): ISelectOption<ContactTypesEnum>[] {
    return [
      {
        code: ContactTypesEnum.Client,
        description: this.$t('crm.view.closeChats.client').toString(),
      },
      {
        code: ContactTypesEnum.Prospect,
        description: this.$t('crm.view.closeChats.prospect').toString(),
      },
    ];
  }

  private async getDepartments(): Promise<ConversationDepartmentModel[]> {
    return (await this.conversationService.getDepartments()).filter((department) => department.nome);
  }

  private async getAttendants(): Promise<UserModel[]> {
    return (await this.activityService.getAttendants()).filter((attendant) => attendant.nome);
  }

  private getContacts(): string[] {
    return [...new Set(this.items.filter((conversation) => conversation.contato && conversation.contato.nome)
      .map((conversation) => conversation.contato.nome))];
  }

  private getContactNumbers(): string[] {
    return [...new Set(this.items.filter((conversation) => conversation.contato && conversation.contato.whatsapp)
      .map((conversation) => conversation.contato.whatsapp))];
  }

  private getDateRanges(): IDateRangeConfig[] {
    return [
      {
        name: `${this.$t('global.today')}`,
        ...DateHelper.getTodayPeriod(),
      },
      {
        name: `${this.$t('global.yesterday')}`,
        ...DateHelper.getYesterdayPeriod(),
      },
      {
        name: `${this.$t('global.currentMonth')}`,
        ...DateHelper.getCurrentMonthPeriod(),
      },
      {
        name: `${this.$t('global.lastMonth')}`,
        ...DateHelper.getLastMonthsPeriod(1),
      },
      {
        name: `${this.$t('global.lastThreeMonths')}`,
        ...DateHelper.getLastMonthsPeriod(3),
      },
      {
        name: `${this.$t('global.lastSixMonths')}`,
        ...DateHelper.getLastMonthsPeriod(6),
      },
      {
        name: `${this.$t('global.lastYear')}`,
        ...DateHelper.getLastYearsPeriod(1),
      },
      {
        name: `${this.$t('global.currentYear')}`,
        ...DateHelper.getCurrentYearPeriod(),
      },
    ];
  }
}
